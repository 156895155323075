
import Vue from "vue";
import store from "@/store";
import API from "@/api/API";

export default Vue.extend({
  name: "Create",

  components: {
    ata: () => import("../components/order_types/ATA/Preview.vue"),
    aaij: () => import("../components/order_types/AAIJ/Preview.vue"),
    exp: () => import("../components/order_types/EXP/Preview.vue"),
    eval: () => import("../components/order_types/EVAL/Preview.vue"),
    cert: () => import("../components/order_types/CERT/Preview.vue")
  },

  data: () => ({
    model: {
      documents: [] as Array<any>,
      goods: [] as Array<any>,
      details: { countries: [] as Array<any> } as any,
      order: {
        exchange_rate: "1.0000",
        document_type: "original",
        source: "operator",
        release_mode: "normal",
        handler_id: 1,
        valid_form: new Date().toISOString().substr(0, 10)
      } as any
    } as any,
    errorMessages: {} as any,
    loading: false as boolean,
    breadcrumb: [
      {
        text: "orders.title",
        disabled: false,
        href: "/orders"
      },
      {
        text: "orders.form.header.preview",
        disabled: true,
        href: `/orders/preview`
      }
    ],
    selectedTab: 0 as number,
    component: "" as string,
    orderComponents: ["ata", "aaij", "cert", "eval", "exp"] as Array<string>,
    comments: {} as any,
    oldComments: null as any,
    isLoading: false as boolean,
    allowedRoles: [
      "admin",
      "chairman",
      "vice_president",
      "subdivision_head",
      "dispatcher"
    ] as Array<string>
  }),

  computed: {
    currentRole() {
      return this.$store.getters["user/currentRole"];
    },
    user() {
      return this.$store.getters["user/info"];
    },
    catEditOrder() {
      return (
        (this.allowedRoles.includes(this.currentRole?.alias) ||
          this.isHandler) &&
        !this.isSubdivisionHead
      );
    },
    isHandler() {
      return this.model.order.handler_id === this.user?.id;
    },
    isSubdivisionHead() {
      return this.currentRole.alias === "subdivision_head";
    }
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    async submit(): Promise<void> {
      this.isLoading = true;

      try {
        const comments = [] as Array<any>;

        for (const key in this.comments) {
          if (this.comments[key]?.text) {
            comments.push(this.comments[key]);
          }
        }

        await this.$API
          .orders()
          .updateComment(Number(this.$route.params.id), { notes: comments });
        this.oldComments = JSON.stringify(this.comments);
        await this.$router.push("/orders");
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_edit")
        );
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.isLoading = false;
    },
    hasChanges(): boolean {
      return JSON.stringify(this.comments) !== this.oldComments;
    },
    async loadData() {
      this.loading = true;
      try {
        const item = await API.orders().show(Number(this.$route.params.id));
        this.component = this.orderComponents[
          Number(item.order.service_type_id) - 1
        ];

        const items = await this.$API
          .orders()
          .getComments(Number(this.$route.params.id));
        this.comments = this.parseComments(items);
        this.oldComments = JSON.stringify(this.comments);

        this.$nextTick(() => {
          this.fullLoaded = true;
          this.model = item;
        });
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    parseComments(array: any) {
      const object = {} as any;

      for (const key of array) {
        object[key.key] = key;
      }
      return object;
    },
    setComments(comments: any) {
      this.comments = comments;
      this.$forceUpdate();
    },
    getCount(): number {
      return Object.keys(this.comments).length;
    },
    toggleView(): void {
      this.$router.push(`/orders/edit/${this.$route.params.id}`);
    }
  }
});
